@import "common";
@import "index";

footer {
  background-color: $color_dark;
}

h1, h2{
  font-weight: 100;
}

h1{
  font-size: $h1_screen_fontsize;
  line-height: ($h1_screen_fontsize+3);
}
h2{
  font-size: $h2_screen_fontsize;
  line-height: ($h2_screen_fontsize+3);
}

.swp_lead {
  font-weight: 300;
  font-size: $text_head_screen;
}

@media (max-width: $max_width_mobile) {
  h1 {
    font-size: $h1_mobile_fontsize;
    line-height: ($h1_mobile_fontsize+2);
  }
  h2 {
    font-size: $h2_mobile_fontsize;
    line-height: ($h2_mobile_fontsize+2);
  }
  .swp_lead {
    font-size: $text_head_mobile;
    line-height: ($text_head_mobile+2);
  }
}

.swp_grid__flexible{
  display: flex;
  @media (max-width: $max_width_mobile) {
    flex-direction: column;
  }

  .swp_grid__element{
    flex: 1;
    margin: 1em;
  }
}

.swp_grid{
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: $max_width_mobile) {
    flex-wrap: wrap;
  }

  .dm_grid__element{
    flex: 1;
    text-align: center;
  }
}

.swp_transition__image{
  padding: 0;
  margin: 0;
  position: absolute;
}

.swp_transition__image-template{
  visibility: hidden;
  display: none;
}

.center{
  text-align: center;
}

.content {
  display: flex;
  flex-direction: column;
}

.swp_footer {
  color: $color_dark_txt;
  display: flex;
  justify-content: space-between;
  font-size: 14px;

  .swp_society{
    color: white;
  }

  div{
    margin-right: 1em;
    width: 100%;
  }

  .swp_footer__middle{
    flex-grow: 1;
  }

  .swp_footer__right{
    text-align: right;
  }
}

.swp_footer .is-large-screen {
  padding: 0.7em;
  margin: 0.7em 1em;
}

.swp_footer .is-small-screen {
  padding: 0;
  margin: 0 1em;
  font-size: 12px;
}

.swp_logo{
  img{
    width:40%;
    height:auto;
  }
  @media (max-width: $max_width_mobile) {
    img{
      width:70%;
    }
  }
  @media (min-width: $min_width_tablet) and (max-width: $max_width_tablet) {
    img{
      width:50%;
    }
  }
}

.swp_button_donation {
  border-radius: 4px;
  font-size: 1.5em;
  border: none;
  background-color: #ffc107;
  color: white;
  max-width: 10em;
  padding: 0.5em 1em;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  vertical-align: middle;
  display: block;
  @include swp_box__shadow;
  padding: .75em .5em;

  &:hover {
    background-color: #ecb306;
  }

  //display: block;background: #70c24a;text-align: center;margin: auto;padding: .75em .5em;color: #000;
}

.swp-blog--button{
  display: block;
  padding: 1em;
  text-align: center;

  > button{
    border-radius: 4px;
    font-size: 13px;
    border: none;
    background-color: #e64a19;
    color: white;
    padding: 0.5em 1em;
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    @include swp_box__shadow;

    &:hover {
      background-color: #B71C1C;
    }
  }
}

.swp-blog--article{
  background-color: white;
  border-radius: 4px;
  padding: 1em;
  margin-bottom: 1em;
  flex: 0 1 49%;
  display: flex;
  flex-direction: column;
  @include swp_box__shadow;
  cursor: pointer;

  > h1 {
    font-size: $h3_screen_fontsize;
    line-height: ($h3_screen_fontsize+2);
  }
  > h2 {
    font-size: $text_head_screen;
    line-height: ($text_head_screen+2);
  }
  @media (max-width: $max_width_mobile) {
    flex: 0 1 auto;
    padding: 0.5em;
    margin: 0.3em 0;
    min-height: 5em;
    > h1 {
      font-size: $text_head_mobile;
      line-height: ($text_head_mobile+2);
    }
    > h2 {
      font-size: $text_head_mobile;
      line-height: ($text_head_mobile+2);
    }
  }
}

.swp-blog--article-head{
  @extend .swp-blog--article;
  flex: 0 1 auto;
  .swp-blog--imgteaser{
    order: 0;
  }
}